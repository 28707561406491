import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Link, withPrefix } from 'gatsby';
import { SEO, LWrap, AssetImage, LayoutLp } from '../../../components/_index';
import Slider from 'react-slick';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/the-club.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const settings = {
    dots: true,
    arrows: true,
    autoplay: false,
    speed: 1500,
  };
  // スクロール開始後にサイド固定の予約モジュールを表示
  const [showDLlinkWrap, setShowDLlinkWrap] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const threshold = 600;

      setShowDLlinkWrap(scrollTop > threshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <LayoutLp footerMargin={true} hideHeader={true}>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/the-club/ogimage.png`}
      />
      <main>
        <div className="p_the-club">
          {/* キービジュ */}
          <div className="p_kv">
            {!isSp ? (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/the-club/kv.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            ) : (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/the-club/kv__sp.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            )}
            <div className="downloadLink_wrapper">
              <div className="downloadLink_label">
                <img src="/assets/images/sp/the-club/app-icon.png" />
                <div className="downloadLink_label_lines">
                  <p className="downloadLink_label_line1">
                    今すぐアプリをダウンロード
                  </p>
                  <p className="downloadLink_label_line2">
                    ロイヤルパークホテルズアプリ
                  </p>
                </div>
              </div>
              <div className="downloadLink_android">
                <img
                  src="/assets/images/sp/the-club/img_android_qr.png"
                  className="qr_image u_pc"
                />
                <a
                  href="https://play.google.com/store/apps/details?id=jp.co.royalparkhotels&hl=ja"
                  target="_blank"
                >
                  <img src="/assets/images/sp/the-club/google-play-badge.png" />
                </a>
              </div>
              <div className="downloadLink_ios">
                <img
                  src="/assets/images/sp/the-club/img_ios_qr.png"
                  className="qr_image u_pc"
                />
                <a
                  href="https://apps.apple.com/jp/app/id6450347390?l=ja&ls=1&mt=8"
                  target="_blank"
                >
                  <img src="/assets/images/sp/the-club/apple.png" />
                </a>
              </div>
            </div>
          </div>

          {showDLlinkWrap && (
            <div className="downloadLink_wrapper following">
              <div className="downloadLink_label u_pc">
                <img src="/assets/images/sp/the-club/app-icon.png" />
                <div className="downloadLink_label_lines">
                  <p className="downloadLink_label_line1">
                    今すぐアプリをダウンロード
                  </p>
                  <p className="downloadLink_label_line2">
                    ロイヤルパークホテルズアプリ
                  </p>
                </div>
              </div>
              <div className="downloadLink_android u_pc">
                <img
                  src="/assets/images/sp/the-club/img_android_qr.png"
                  className="qr_image"
                />
                <a
                  href="https://play.google.com/store/apps/details?id=jp.co.royalparkhotels&hl=ja"
                  target="_blank"
                >
                  <img src="/assets/images/sp/the-club/google-play-badge.png" />
                </a>
              </div>
              <div className="downloadLink_ios u_pc">
                <img
                  src="/assets/images/sp/the-club/img_ios_qr.png"
                  className="qr_image"
                />
                <a
                  href="https://apps.apple.com/jp/app/id6450347390?l=ja&ls=1&mt=8"
                  target="_blank"
                >
                  <img src="/assets/images/sp/the-club/apple.png" />
                </a>
              </div>

              <div className="u_sp">
                <div className="downloadLink_label">
                  <img
                    src="/assets/images/sp/the-club/app-icon.png"
                    className="app_icon"
                  />
                  <div className="downloadLink_button">
                    <div className="downloadLink_label_lines">
                      <p className="downloadLink_label_line1">
                        今すぐアプリをダウンロード
                      </p>
                    </div>
                    <div className="downloadLink_app">
                      <div className="downloadLink_android">
                        <a
                          href="https://play.google.com/store/apps/details?id=jp.co.royalparkhotels&hl=ja"
                          target="_blank"
                        >
                          <img src="/assets/images/sp/the-club/google-play-badge.png" />
                        </a>
                      </div>
                      <div className="downloadLink_ios">
                        <a
                          href="https://apps.apple.com/jp/app/id6450347390?l=ja&ls=1&mt=8"
                          target="_blank"
                        >
                          <img src="/assets/images/sp/the-club/apple.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <section className="featuresWrapper">
            <LWrap>
              <h3 className="sub_title">
                アプリでロイヤルパークホテルズを
                <br className="u_sp" />
                より便利に
                <br />
                <span className="large">3つの便利機能</span>
                <img
                  src="/assets/images/sp/the-club/subtitle_img1.png"
                  alt=""
                  className="subtitle_img1"
                />
              </h3>
              <div className="feature_list_item">
                <div className="feature_list_item_left">
                  <div>
                    <img
                      src="/assets/images/sp/the-club/img_label01.png"
                      alt=""
                    />
                  </div>
                  <h4>最安値のプランを簡単予約</h4>
                  <p>
                    最安値を保証し、会員限定プランも素早く便利に予約が可能です！
                  </p>
                </div>
                <div className="feature_list_item_right">
                  <img
                    src="/assets/images/sp/the-club/img_feature.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="feature_list_item reverse">
                <div className="feature_list_item_left">
                  <div>
                    <img
                      src="/assets/images/sp/the-club/img_label02.png"
                      alt=""
                    />
                  </div>
                  <h4>
                    お得な会員特典クーポンを
                    <br />
                    アプリでGET
                  </h4>
                  <p>
                    限定クーポンやキャンペーン情報を見逃さないよう、公式アプリを通じてお知らせします。
                  </p>
                </div>
                <div className="feature_list_item_right">
                  <img
                    src="/assets/images/sp/the-club/img_feature02.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="feature_list_item">
                <div className="feature_list_item_left">
                  <div>
                    <img
                      src="/assets/images/sp/the-club/img_label03.png"
                      alt=""
                    />
                  </div>
                  <h4>
                    デジタル会員証で
                    <br />
                    スマートなチェックイン
                  </h4>
                  <p>
                    アプリのデジタル会員証でチェックイン時の面倒な手続きが不要に！
                    <br />
                    予約情報や宿泊履歴、ポイント残高を簡単に確認できます。
                  </p>
                </div>
                <div className="feature_list_item_right">
                  <img
                    src="/assets/images/sp/the-club/img_feature03.png"
                    alt=""
                  />
                </div>
              </div>
            </LWrap>
          </section>

          <section className="rewardWrapper">
            <Link
              to="https://www.royalparkhotels.co.jp/the-club/benefits/"
              target="_blank"
              rel="noopner noreferrer"
            >
              {!isSp ? (
                <figure className={`imgWrapper`}>
                  <AssetImage
                    src="/assets/images/sp/the-club/reward_image.png"
                    alt=""
                    loading="lazy"
                    className="rewardImage"
                  />
                </figure>
              ) : (
                <figure className={`imgWrapper`}>
                  <AssetImage
                    src="/assets/images/sp/the-club/reward_image__sp.png"
                    alt=""
                    loading="lazy"
                    className="rewardImage"
                  />
                </figure>
              )}
            </Link>
            <ul className="c_noteList u_mb60">
              <li>
                特典の利用には条件があります。
                <br className="u_sp" />
                詳しくは
                <Link
                  to="https://www.royalparkhotels.co.jp/the-club/benefits/"
                  target="_blank"
                  rel="noopner noreferrer"
                >
                  会員特典ページ
                </Link>
                をご覧ください。
              </li>
            </ul>

            {/* <h4>今なら素敵な特典</h4>
            <Link
              to="https://www.royalparkhotels.co.jp/the-club/campaign/"
              target="_blank"
              rel="noopner noreferrer"
            >
              {!isSp ? (
                <figure className={`imgWrapper`}>
                  <AssetImage
                    src="/assets/images/sp/the-club/reward_image02.png"
                    alt=""
                    loading="lazy"
                    className="rewardImage"
                  />
                </figure>
              ) : (
                <figure className={`imgWrapper`}>
                  <AssetImage
                    src="/assets/images/sp/the-club/reward_image02__sp.png"
                    alt=""
                    loading="lazy"
                    className="rewardImage"
                  />
                </figure>
              )}
            </Link> */}
          </section>

          <section className="howtoWrapper">
            <h3 className="sub_title">
              ご利用方法
              <img
                src="/assets/images/sp/the-club/subtitle_img2.png"
                alt=""
                className="subtitle_img2"
              />
            </h3>
            <div className="tab_wrapper">
              <div className="tab-002">
                <label>
                  <input type="radio" name="tab-002" defaultChecked />
                  新規
                  <br className="u_sp" />
                  会員登録の方
                </label>
                <div className="howto_tab_new">
                  <h3>アプリで簡単2ステップ登録</h3>
                  <p className="downloadnow">今すぐアプリをダウンロード</p>

                  <div className="downloadLink_wrapper">
                    <div className="downloadLink_android">
                      <img
                        src="/assets/images/sp/the-club/img_android_qr.png"
                        className="qr_image u_pc"
                      />
                      <a
                        href="https://play.google.com/store/apps/details?id=jp.co.royalparkhotels&hl=ja"
                        target="_blank"
                      >
                        <img src="/assets/images/sp/the-club/google-play-badge.png" />
                      </a>
                    </div>
                    <div className="downloadLink_ios">
                      <img
                        src="/assets/images/sp/the-club/img_ios_qr.png"
                        className="qr_image u_pc"
                      />
                      <a
                        href="https://apps.apple.com/jp/app/id6450347390?l=ja&ls=1&mt=8"
                        target="_blank"
                      >
                        <img src="/assets/images/sp/the-club/apple.png" />
                      </a>
                    </div>
                  </div>

                  <div className="howto_steps u_pc">
                    <div className="step">
                      <p className="step_number"></p>
                      <p className="step_text">
                        アプリを起動し、ホーム画面から
                        <br />
                        「会員証表示/会員登録」
                        <br />
                        ボタンをタップします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step.png" />
                      </p>
                    </div>

                    <div className="step_arrow">
                      <img src="/assets/images/sp/the-club/howto_arrow_next.svg" />
                    </div>

                    <div className="step">
                      <p className="step_number">
                        <img src="/assets/images/sp/the-club/img_step01.png" />
                      </p>
                      <p className="step_text">
                        Machi Passユーザ登録をします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_new01.png" />
                      </p>
                    </div>

                    <div className="step_arrow">
                      <img src="/assets/images/sp/the-club/howto_arrow_next.svg" />
                    </div>

                    <div className="step">
                      <p className="step_number">
                        <img src="/assets/images/sp/the-club/img_step02.png" />
                      </p>
                      <p className="step_text">
                        ザ クラブ会員登録後、
                        <br />
                        ご利用いただけます。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_new02.png" />
                      </p>
                    </div>
                  </div>

                  <Slider {...settings} className="slider u_sp">
                    <div>
                      <p className="step_number"></p>
                      <p className="step_text">
                        アプリを起動し、ホーム画面から
                        <br />
                        「会員証表示/会員登録」
                        <br />
                        ボタンをタップします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step.png" />
                      </p>
                    </div>
                    <div>
                      <p className="step_number">
                        <img src="/assets/images/sp/the-club/img_step01.png" />
                      </p>
                      <p className="step_text">
                        Machi Passユーザ登録をします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_new01.png" />
                      </p>
                    </div>
                    <div>
                      <img src="/assets/images/sp/the-club/img_step02.png" />
                      <p className="step_text">
                        ザ クラブ会員登録後、
                        <br />
                        ご利用いただけます。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_new02.png" />
                      </p>
                    </div>
                  </Slider>

                  <div className="howto_reward">
                    <div className="reward_left">
                      <img src="/assets/images/sp/the-club/howto_reward_title.png" />
                      <p>
                        ロイヤルパークホテルズでは、会員様限定のお得な特典をご用意
                        <br />
                        入会金・年会費は無料、さらに宿泊ベストレート保証
                      </p>
                      <a
                        className="register_button"
                        href="https://www.royalparkhotels.co.jp/the-club/agreement/"
                        target="_blank"
                      >
                        ザ クラブ 会員登録をする
                      </a>
                      <table className="u_pc">
                        <tbody>
                          <tr>
                            <th>※</th>
                            <td>
                              ザ クラブロイヤルパークホテルズ会員の詳細は
                              <a
                                href="https://www.royalparkhotels.co.jp/the-club/"
                                target="_blank"
                              >
                                こちら
                              </a>
                              <img
                                src="/assets/images/sp/the-club/icon_link.svg"
                                className="icon"
                              />
                            </td>
                          </tr>
                          <tr>
                            <th>※</th>
                            <td>
                              ご入会方法の詳細は
                              <a
                                href="https://www.royalparkhotels.co.jp/the-club/assets/pdf/MachiPass_manual.pdf"
                                target="_blank"
                              >
                                こちら
                              </a>
                              <img
                                src="/assets/images/sp/the-club/icon_pdf.svg"
                                className="icon"
                              />
                            </td>
                          </tr>
                          <tr className="red">
                            <th>※</th>
                            <td>
                              会員登録にはMachi
                              Passのユーザ登録が必要になります。
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="reward_right">
                      <div>
                        <img src="/assets/images/sp/the-club/howto_benefit01.png" />
                        <p>
                          <span className="red">お得な</span>
                          <br />
                          宿泊プラン
                        </p>
                      </div>
                      <div>
                        <img src="/assets/images/sp/the-club/howto_benefit02.png" />
                        <p>
                          レストラン・
                          <br />
                          ショップ<span className="red">割引</span>
                        </p>
                      </div>
                      <div>
                        <img src="/assets/images/sp/the-club/howto_benefit03.png" />
                        <p>
                          <span className="red">ポイント</span>
                          <br />
                          特典
                        </p>
                      </div>
                      <div>
                        <img src="/assets/images/sp/the-club/howto_benefit04.png" />
                        <p>
                          <span className="red">会員ステージ</span>
                          <br />
                          制度
                        </p>
                      </div>
                      <div>
                        <img src="/assets/images/sp/the-club/howto_benefit05.png" />
                        <p>
                          <span className="red">アニバーサリー</span>
                          <br />
                          特典
                        </p>
                      </div>
                      <div>
                        <img src="/assets/images/sp/the-club/howto_benefit06.png" />
                        <p>
                          <span className="red">優待施設</span>
                          <br />
                          での特典
                        </p>
                      </div>
                    </div>
                    <table className="u_sp u_mt30">
                      <tbody>
                        <tr>
                          <th>※</th>
                          <td>
                            ザ クラブロイヤルパークホテルズ会員の詳細は
                            <a
                              href="https://www.royalparkhotels.co.jp/the-club/"
                              target="_blank"
                            >
                              こちら
                            </a>
                            <img
                              src="/assets/images/sp/the-club/icon_link.svg"
                              className="icon"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>※</th>
                          <td>
                            ご入会方法の詳細は
                            <a
                              href="https://www.royalparkhotels.co.jp/the-club/assets/pdf/MachiPass_manual.pdf"
                              target="_blank"
                            >
                              こちら
                            </a>
                            <img
                              src="/assets/images/sp/the-club/icon_pdf.svg"
                              className="icon"
                            />
                          </td>
                        </tr>
                        <tr className="red">
                          <th>※</th>
                          <td>
                            会員登録にはMachi Passのユーザ登録が必要になります。
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <label>
                  <input type="radio" name="tab-002" />ザ クラブ
                  <br className="u_sp" />
                  会員の方
                </label>
                <div className="howto_tab_member">
                  <h3>
                    アプリでログインし、
                    <br className="u_sp" />
                    お得な情報をいつでも入手
                  </h3>

                  <div className="howto_steps u_pc">
                    <div className="step">
                      <p className="step_number"></p>
                      <p className="step_text">
                        アプリを起動し、ホーム画面から
                        <br />
                        「会員証表示/会員登録」ボタンを
                        <br />
                        タップします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step.png" />
                      </p>
                    </div>

                    <div className="step_arrow">
                      <img src="/assets/images/sp/the-club/howto_arrow_next.svg" />
                    </div>

                    <div className="step">
                      <p className="step_number">
                        <img src="/assets/images/sp/the-club/img_step01.png" />
                      </p>
                      <p className="step_text">
                        Machi Passユーザ登録をします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_user01.png" />
                      </p>
                    </div>

                    <div className="step_arrow">
                      <img src="/assets/images/sp/the-club/howto_arrow_next.svg" />
                    </div>

                    <div className="step">
                      <p className="step_number">
                        <img src="/assets/images/sp/the-club/img_step02.png" />
                      </p>
                      <p className="step_text">
                        受信したメールに記載されているURLよりMachi
                        Passユーザ登録完了後、ご利用いただけます。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_user02.png" />
                      </p>
                    </div>
                  </div>

                  <Slider {...settings} className="slider u_sp">
                    <div>
                      <p className="step_number"></p>
                      <p className="step_text">
                        アプリを起動し、ホーム画面から
                        <br />
                        「会員証表示/会員登録」
                        <br />
                        ボタンをタップします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step.png" />
                      </p>
                    </div>
                    <div>
                      <p className="step_number">
                        <img src="/assets/images/sp/the-club/img_step01.png" />
                      </p>
                      <p className="step_text">
                        Machi Passユーザ登録をします。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_user01.png" />
                      </p>
                    </div>
                    <div>
                      <p className="step_number">
                        <img src="/assets/images/sp/the-club/img_step02.png" />
                      </p>
                      <p className="step_text">
                        受信したメールに記載されている
                        <br />
                        URLよりMachi Passユーザ登録完了後、
                        <br />
                        ご利用いただけます。
                      </p>
                      <p className="step_image">
                        <img src="/assets/images/sp/the-club/howto_step_user02.png" />
                      </p>
                    </div>
                  </Slider>

                  <table>
                    <tbody>
                      <tr>
                        <th>※</th>
                        <td>
                          会員登録にはMachi Passのユーザ登録が必要になります。
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="downloadnow">今すぐアプリをダウンロード</p>

                  <div className="downloadLink_wrapper bg_white">
                    <div className="downloadLink_android">
                      <img
                        src="/assets/images/sp/the-club/img_android_qr.png"
                        className="qr_image u_pc"
                      />
                      <a
                        href="https://play.google.com/store/apps/details?id=jp.co.royalparkhotels&hl=ja"
                        target="_blank"
                      >
                        <img src="/assets/images/sp/the-club/google-play-badge.png" />
                      </a>
                    </div>
                    <div className="downloadLink_ios">
                      <img
                        src="/assets/images/sp/the-club/img_ios_qr.png"
                        className="qr_image u_pc"
                      />
                      <a
                        href="https://apps.apple.com/jp/app/id6450347390?l=ja&ls=1&mt=8"
                        target="_blank"
                      >
                        <img src="/assets/images/sp/the-club/apple.png" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="machipassWrapper">
            <LWrap>
              <h3>Machi Passとは</h3>
              <p className="u_tal_sp">
                三菱地所グループエリアのサービスをご利用できるデジタル共通IDです。
                <br />
                お得なクーポンがもらえる、ポイントを貯める・使う事ができるサービス等、
                <br />
                さまざまなサービスをMachi PassのIDひとつでご利用可能となります。
              </p>

              <a
                href="https://id-info.mec.co.jp/introduction.html"
                target="_blank"
              >
                {!isSp ? (
                  <figure className={`imgWrapper`}>
                    <AssetImage
                      src="/assets/images/sp/the-club/img_machipass.png"
                      alt=""
                      loading="lazy"
                      className="rewardImage"
                    />
                  </figure>
                ) : (
                  <figure className={`imgWrapper`}>
                    <AssetImage
                      src="/assets/images/sp/the-club/img_machipass__sp.png"
                      alt=""
                      loading="lazy"
                      className="rewardImage"
                    />
                  </figure>
                )}
              </a>
            </LWrap>
          </section>
        </div>
      </main>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
